<template>
  <v-container>
    <v-row align="center">
      <v-col
        cols="12"
        sm="6"
        md="4"
        xl="3"
      >
        <v-autocomplete
          v-model="channel"
          :label="$t('channel')"
          :items="channels"
          item-text="label"
          item-value="uuid"
          outlined
          hide-details
          clearable
          :disabled="loading || isUpdatingTimeSlot"
        >
          <template v-slot:item="data">
            <template>
              <div class="py-1">
                <div style="font-weight: 500;">
                  {{ data.item.label }}
                </div>
                <div class="caption">
                  {{ data.item.uuid }}
                </div>
              </div>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        xl="3"
      >
        <v-autocomplete
          v-model="location"
          :label="$t('location')"
          :items="locations"
          item-text="label"
          item-value="uuid"
          outlined
          hide-details
          clearable
          :disabled="loading || isUpdatingTimeSlot"
        >
          <template v-slot:item="data">
            <template>
              <div class="py-1">
                <div style="font-weight: 500;">
                  {{ data.item.label || this.$t('locationIsMissingLabel') }}
                </div>
                <div class="caption">
                  {{ data.item.uuid }}
                </div>
              </div>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        xl="3"
      >
        <v-autocomplete
          v-model="item"
          :label="$t('item')"
          :items="sortedItems"
          item-text="title"
          item-value="uuid"
          outlined
          hide-details
          clearable
          :disabled="loading || isUpdatingTimeSlot || disabled"
        >
          <template v-slot:item="data">
            <template>
              <div>
                <div style="font-weight: 500;">
                  {{ data.item.title }}
                </div>
                <div class="caption">
                  {{ data.item.uuid }}
                </div>
              </div>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="12"
        xl="3"
      >
        <v-btn
          block
          x-large
          :color="primaryColor"
          :dark="!primaryColorIsLight && !loading"
          @click="getAvailableTimesSlots"
          :disabled="loading || isUpdatingTimeSlot"
          :loading="loading"
        >
          {{ $t('viewSlots') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      align="center"
      class="pb-3"
    >
      <v-col
        cols="12"
        md="6"
        lg="9"
        order="10"
        order-md="1"
      >
        <v-card>
          <v-container
            fluid
            class="py-0"
          >
            <v-row
              align="center"
              justify="center"
            >
              <v-col
                class="pa-0 grow"
                cols="auto"
              >
                <v-btn
                  x-large
                  block
                  depressed
                  tile
                  :color="primaryColor"
                  :dark="!primaryColorIsLight && !loading"
                  :disabled="loading || isUpdatingTimeSlot"
                  style="border-top-left-radius: 5px; border-bottom-left-radius: 5px;"
                  @click="previousDay"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </v-col>
              <v-divider
                vertical
                :dark="loading"
              />
              <v-col
                class="pa-0"
                cols="auto"
                md="10"
                ref="button"
              >
                <v-menu
                  bottom
                  offset-y
                  tile
                  max-height="100%"
                  :max-width="buttonWidth"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-large
                      block
                      depressed
                      tile
                      :color="primaryColor"
                      :dark="!primaryColorIsLight && !loading"
                      :disabled="loading || isUpdatingTimeSlot"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ selectedDate }}
                    </v-btn>
                  </template>
                  <v-date-picker
                    :color="primaryColor"
                    flat
                    style="border-radius: 0; min-height: 350px;"
                    full-width
                    no-title
                    v-model="selectedDate"
                  />
                </v-menu>
              </v-col>
              <v-divider
                vertical
                :dark="loading"
              />
              <v-col
                class="pa-0 grow"
                cols="auto"
              >
                <v-btn
                  x-large
                  block
                  depressed
                  tile
                  :color="primaryColor"
                  :dark="!primaryColorIsLight && !loading"
                  :disabled="loading || isUpdatingTimeSlot"
                  style="border-top-right-radius: 5px; border-bottom-right-radius: 5px;"
                  @click="nextDay"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
        order="1"
        order-md="10"
      >
        <v-btn
          x-large
          block
          :color="primaryColor"
          :dark="!primaryColorIsLight && !disabled"
          :disabled="disabled"
        >
          <v-icon left>
            mdi-sort
          </v-icon>{{ $t('sort') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="pt-3">
      <v-col cols="12">
        <div style="font-weight: 500; font-size: 30px;">
          {{ currentlySelectedWeekday.text }}
        </div>
      </v-col>
      <v-col
        cols="12"
        v-if="loading"
      >
        <ProgressCircular loading />
      </v-col>
      <v-col
        cols="12"
        v-else-if="timeSlots === null || timeSlots === undefined || timeSlots.length < 1"
      >
        <div class="text--secondary">
          {{ $t('noTimeSlotsFound') }}
        </div>
      </v-col>
      <v-col
        cols="12"
        v-else
      >
        <div class="pb-6">
          <v-btn
            v-if="dayIsBlocked"
            color="primary"
            block
            @click="unblockDay"
            x-large
            :disabled="isUpdatingTimeSlot"
          >
            {{ $t('unblockDay') }}
          </v-btn>
          <v-btn
            v-else
            color="error"
            block
            @click="blockDay"
            x-large
            :disabled="isUpdatingTimeSlot"
          >
            {{ $t('blockDay') }}
          </v-btn>
        </div>
        <v-list class="py-0">
          <template v-for="(hour) in hours">
            <v-list-item
              :key="hour.text + '-title'"
              style="font-size: 20px; font-weight: 500; background-color: #cccccc;"
            >
              <v-list-item-content>
                {{ hour.text }}
              </v-list-item-content>
              <v-list-item-action v-if="timeSlotsByHour(hour).length > 0">
                <v-btn
                  color="primary"
                  v-if="hourIsBlocked(hour)"
                  @click="unblockHour(hour)"
                  large
                  :disabled="isUpdatingTimeSlot"
                >
                  {{ $t('unblockHour') }}
                </v-btn>
                <v-btn
                  color="error"
                  v-else
                  @click="blockHour(hour)"
                  large
                  :disabled="isUpdatingTimeSlot"
                >
                  {{$t('blockHour')}}
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item
              :key="hour.text + '-slots'"
              class="px-0"
            >
              <v-list-item-content class="py-0">
                <v-list
                  class="py-0"
                  v-if="timeSlotsByHour(hour).length > 0"
                >
                  <template v-for="(slot, i) in timeSlotsByHour(hour)">
                    <v-list-item :key="slot.startTimeInMillis + '-' + i">
                      <v-list-item-avatar
                        :color="slot.isBlocked ? 'red' : 'green'"
                        size="48"
                        rounded
                        class="white--text"
                      >
                        {{ slot.label }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <div v-if="slot.isBlocked">
                            {{$t('blocked')}}
                          </div>
                          <div v-else>
                            {{$t('available')}}
                          </div>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <div v-if="selectedItem && selectedItem.maximumGroupedQuantity">
                            {{$t('maxAvailable:')}} {{ selectedItem.maximumGroupedQuantity }}
                          </div>
                          <div>{{$t('currentlyAvailable:')}} {{ slot.availableCount }}</div>
                          <div>{{$t('currentlyReserved:')}} {{ slot.reservationCount }}</div>
                          <div>{{$t('currentlyBooked:')}} {{ slot.bookingCount }}</div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          v-if="slot.isBlocked"
                          color="primary"
                          @click="unblockSlot(slot)"
                          large
                          :disabled="isUpdatingTimeSlot"
                        >
                          {{$t('unblockSlot')}}
                        </v-btn>
                        <v-btn
                          v-else
                          color="error"
                          @click="blockSlot(slot)"
                          large
                          :disabled="isUpdatingTimeSlot"
                        >
                          {{$t('blockSlot')}}
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                      :key="slot.startTimeInMillis + '-' + i + '-d'"
                      v-if="i < (timeSlotsByHour(hour).length - 1)"
                    />
                  </template>
                </v-list>
                <div
                  v-else
                  class="text--secondary px-4"
                >
                  {{$t('noSlotsForThisHour')}}
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProgressCircular from "@/components/common/ProgressCircular"

export default {
  name: "Slots",
  components: {
    ProgressCircular
  },
  data() {
    return {
      channel: null,
      location: null,
      item: null,
      bWidth: 500,
      selectedDate: null,
      slots: [],
      disabled: true,
      show: false
    }
  },
  mounted() {
    if(this.$refs && this.$refs.button) {
      this.bWidth = this.$refs.button.offsetWidth
    }
    this.selectedDate = this.today.toISOString().substr(0, 10)
  },
  computed: {
    today() {
      return new Date(this.systemTimeInMillis * 1)
    },
    systemTimeInMillis() {
      return this.$store.getters.systemTime
    },
    channels() {
      return this.$store.state.channels
    },
    locations() {
      return this.$store.state.locations
    },
    items() {
      return this.$store.state.items
    },
    sortedItems() {
      let itemGroups = {}
      for(let i = 0; i < this.items.length; i++) {
        const item = this.items[i]
        if(!itemGroups[item.type]) {
          itemGroups[item.type] = []
        }
        itemGroups[item.type].push(item)
      }
      let sorted = []
      for(let i in itemGroups) {
        let header = i
        let itemGroup = itemGroups[i]
        if(itemGroup.length > 1) {
          header = header + 's'
        }
        sorted.push({ header: header })
        sorted.push(...itemGroup)
      }
      return sorted
    },
    primaryColor() {
      return this.$store.getters.primaryColor
    },
    primaryColorIsLight() {
      return this.$store.getters.colorIsLight(this.primaryColor)
    },
    buttonWidth() {
      if(this.$vuetify.breakpoint.width <= 600) {
        return '100%'
      }
      return this.bWidth
    },
    isGettingTimeSlots() {
      return this.$store.getters.isGettingTimeSlots
    },
    timeSlots() {
      return this.slots
    },
    timestampStartOfSelectedDay() {
      if(this.selectedDate) {
        return new Date(this.selectedDate).setUTCHours(0,0,0,0)
      }
      return 0
    },
    timestampEndOfSelectedDay() {
      if(this.selectedDate) {
        return new Date(this.selectedDate).setUTCHours(23,59,59,999)
      }
      return 0
    },
    payload() {
      let payload = {}

      if(this.timestampStartOfSelectedDay > 0) {
        payload.startTimeInMillis = this.timestampStartOfSelectedDay
      }

      if(this.timestampEndOfSelectedDay > 0) {
        payload.endTimeInMillis = this.timestampEndOfSelectedDay
      }

      if(this.channel) {
        payload.channelUUID = this.channel
      }

      if(this.location) {
        payload.locationUUID = this.location
      }

      if(this.item) {
        payload.itemUUID = this.item
      }

      return payload
    },
    isGettingAvailableTimeSlots() {
      return this.$store.state.gettingAvailableTimeSlots
    },
    loading() {
      return this.isGettingTimeSlots || this.isGettingAvailableTimeSlots
    },
    hours() {
      let hours = []
      let start = new Date(this.selectedDate).setUTCHours(0,0,0,0)
      const end = this.timestampEndOfSelectedDay
      const hourAsMilliseconds = 60 * 60 * 1000
      for(let i = start; i <= end; i += hourAsMilliseconds) {
        const d = new Date(i)
        hours.push({
          text: d.getUTCHours().toString().padStart(2, '0') + ':' + d.getUTCMinutes().toString().padStart(2, '0'),
          hourInMillis: i,
          nextHourInMillis: i + hourAsMilliseconds
        })
      }
      return hours
    },
    selectedItem() {
      if(this.item !== null && this.item !== undefined && Array.isArray(this.items)) {
        return this.items.find(i => i.uuid === this.item)
      }
      return null
    },
    dayBlockPayload() {
      let payload = {}

      const organization = this.$store.getters.organization
      if(organization === null || organization === undefined) {
        return
      }

      payload.startTimeInMillis = this.timestampStartOfSelectedDay
      payload.endTimeInMillis = this.timestampEndOfSelectedDay
      payload.organization = organization.uuid
      payload.channel = this.channel
      payload.location = this.location
      // payload.item = this.item

      return payload
    },
    dayIsBlocked() {
      if(Array.isArray(this.timeSlots) && this.timeSlots.length > 0) {
        return this.timeSlots.every(slot => slot.isBlocked === true)
      }
      return false
    },
    isUpdatingTimeSlot() {
      return this.$store.getters.isUpdatingTimeSlot
    },
    weekdays() {
      return this.$store.getters.weekdays
    },
    currentlySelectedWeekday() {
      const selectedDay = new Date(this.selectedDate).getUTCDay()
      return this.weekdays.find(d => d.value === selectedDay)
    }
  },
  methods: {
    nextDay() {
      let d = new Date(this.selectedDate)
      this.selectedDate = new Date(d.setUTCDate(d.getUTCDate() + 1)).toISOString().substr(0, 10)
    },
    previousDay() {
      let d = new Date(this.selectedDate)
      this.selectedDate = new Date(d.setUTCDate(d.getUTCDate() - 1)).toISOString().substr(0, 10)
    },
    getAvailableTimesSlots() {
      this.$store.dispatch('getAvailableTimeSlots', this.payload).then(result => {
        this.slots = result
      })
    },
    timeSlotsByHour(hour) {
      if(hour === null || hour === undefined) {
        return []
      }
      return this.timeSlots.filter(slot => slot.timestampInMillis >= hour.hourInMillis && slot.timestampInMillis < hour.nextHourInMillis)
    },
    hourIsBlocked(hour) {
      const slots = this.timeSlotsByHour(hour)
      if(slots.length > 0) {
        return slots.every(slot => slot.isBlocked === true)
      } else {
        return false
      }
    },
    slotBlockPayload(slot) {
      let payload = {}

      const organization = this.$store.getters.organization
      if(organization === null || organization === undefined) {
        return
      }

      payload.startTimeInMillis = slot.timestampInMillis
      payload.endTimeInMillis = slot.timestampInMillis + slot.paddingInMillis
      payload.organization = organization.uuid
      payload.channel = this.channel
      payload.location = this.location
      // payload.item = this.item

      return payload
    },
    blockSlot(slot) {
      this.$store.dispatch('blockTimeSlot', this.slotBlockPayload(slot)).then(result => {
        if(result === true || result === false) {
          slot.isBlocked = result
        }
      })
    },
    unblockSlot(slot) {
      this.$store.dispatch('unblockTimeSlot', this.slotBlockPayload(slot)).then(result => {
        if(result === true || result === false) {
          slot.isBlocked = !result
        }
      })
    },
    hourBlockPayload(hour) {
      let payload = {}

      const organization = this.$store.getters.organization
      if(organization === null || organization === undefined) {
        return
      }

      payload.startTimeInMillis = hour.hourInMillis
      payload.endTimeInMillis = hour.nextHourInMillis - 1
      payload.organization = organization.uuid
      payload.channel = this.channel
      payload.location = this.location
      // payload.item = this.item

      return payload
    },
    blockHour(hour) {
      this.$store.dispatch('blockTimeSlot', this.hourBlockPayload(hour)).then(result => {
        if(result === true || result === false) {
          let slots = this.timeSlotsByHour(hour)
          for(let i = 0; i < slots.length; i++) {
            slots[i].isBlocked = result
          }
        }
      })
    },
    unblockHour(hour) {
      this.$store.dispatch('unblockTimeSlot', this.hourBlockPayload(hour)).then(result => {
        if(result === true || result === false) {
          let slots = this.timeSlotsByHour(hour)
          for(let i = 0; i < slots.length; i++) {
            slots[i].isBlocked = !result
          }
        }
      })
    },
    blockDay() {
      this.$store.dispatch('blockTimeSlot', this.dayBlockPayload).then(result => {
        if(result === true || result === false) {
          let slots = this.timeSlots
          for(let i = 0; i < slots.length; i++) {
            slots[i].isBlocked = result
          }
        }
      })
    },
    unblockDay() {
      this.$store.dispatch('unblockTimeSlot', this.dayBlockPayload).then(result => {
        if(result === true || result === false) {
          let slots = this.timeSlots
          for(let i = 0; i < slots.length; i++) {
            slots[i].isBlocked = !result
          }
        }
      })
    }
  },
  watch: {
    selectedDate() {
      if(this.channel || this.location || this.item) {
        this.getAvailableTimesSlots()
      }
    }
  }
}
</script>

<style scoped>

</style>
